import React from "react";

import Image from "next/image";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useDispatch } from "react-redux";
import { setLoginOpen } from "@/store/slices/LoginSlice";
import { updateAuthTabStatus } from "@/store/slices/commonSlice";
import MobileDrawer from "./drawer";
import Link from "next/link";

function Mobile() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const DRAWER = React.useMemo(
    () => <MobileDrawer open={open} onClose={() => setOpen(false)} />,
    [open]
  );
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Box
            //aria-label="menu"
            onClick={() => setOpen(true)}
            // disableElevation
            sx={{ minWidth: 0, width: "fit-content", color: "#2a5798" }}
          >
            <span className="icon-menu"></span>
          </Box>
        </Grid>
        <Grid item xs={7} pl={1}>
          <Link href="/">
            <Image
              src="/logo.svg"
              height={25}
              alt="Jobs in Education"
              title="Jobs in Education"
              width={100}
            />
          </Link>
        </Grid>
        <Grid item xs={4} textAlign="end">
          <Box
            //aria-label="Login"
            sx={{
              borderRadius: "4px",
              fontSize: "16px",
              color: "#2a5798",
              fontWeight: 600,
            }}
            onClick={() => {
              dispatch(setLoginOpen(true));
              dispatch(updateAuthTabStatus("Seeker"));
            }}
          >
            <span className="icon-usernew" style={{fontSize:"24px", color:"#1C1B1B"}}></span>
          </Box>
        </Grid>
      </Grid>
      {DRAWER}
    </>
  );
}

export default Mobile;
