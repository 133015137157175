export const categories = {
  slug: "Jobs by Categories",
  data: [
    {
      institutionCategoryName: "Coaching",
      count: 437,
      approxCount: "435+",
      searchKeyword: "Coaching Jobs",
      img: `${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/home/footer/catcoach.png`,
    },
    {
      institutionCategoryName: "School",
      count: 1210,
      approxCount: "1205+",
      searchKeyword: "School Jobs",
      img: `${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/home/footer/catschool.png`,
    },
    {
      institutionCategoryName: "Pre-School",
      count: 116,
      approxCount: "115+",
      searchKeyword: "Pre-School Jobs",
      img: `${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/home/footer/catpreschool.png`,
    },
    {
      institutionCategoryName: "EdTech",
      count: 221,
      approxCount: "220+",
      searchKeyword: "EdTech Jobs",
      img: `${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/home/footer/catedtech.png`,
    },
    {
      institutionCategoryName: "College/University",
      count: 992,
      approxCount: "990+",
      searchKeyword: "College/University Jobs",
      img: `${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/home/footer/catcollege.png`,
    },
    {
      institutionCategoryName: "Vocational Training Institute",
      count: 38,
      approxCount: "35+",
      searchKeyword: "Vocational Training Institute Jobs",
      img: `${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/home/footer/catpresentation.png`,
    },
  ],
};

export const location = {
  slug: "Jobs by Locations",
  data: [
    {
      cityID: 1558,
      title: "Teacher jobs in Bangalore",
      link: "/teacher-jobs-near-bengaluru",
    },
    {
      cityID: 2763,
      title: "Teacher jobs in Pune",
      link: "/teacher-jobs-in-pune",
    },
    {
      cityID: 3659,
      title: "Teacher jobs in Chennai",
      link: "/teacher-jobs-in-chennai",
    },
    {
      cityID: 5583,
      title: "Teacher jobs in Kolkata",
      link: "/teacher-jobs-near-kolkata",
    },
    {
      cityID: 4460,
      title: "Teacher jobs in Hyderabad",
      link: "/teacher-jobs-in-hyderabad",
    },
    {
      cityID: 783,
      title: "Teacher jobs in Ahmedabad",
      link: "/teacher-jobs-in-ahmedabad",
    },
    {
      cityID: 2707,
      title: "Teacher jobs in Mumbai",
      link: "/teacher-jobs-near-mumbai",
    },
    {
      cityID: 3378,
      title: "Teacher jobs in Jaipur",
      link: "/teacher-jobs-in-jaipur",
    },
    {
      cityID: 4933,
      title: "Teacher jobs in Lucknow",
      link: "/teacher-jobs-in-lucknow",
    },
    {
      cityID: 706,
      title: "Teacher jobs in Delhi",
      link: "/teacher-jobs-near-delhi",
    },
    {
      cityID: 2229,
      title: "Teacher jobs in Indore",
      link: "/teacher-jobs-in-indore",
    },
    {
      cityID: 2995,
      title: "Teacher jobs in Bhubaneswar",
      link: "/teacher-jobs-in-bhubaneswar",
    },
    {
      cityID: 3683,
      title: "Teacher jobs in Coimbatore",
      link: "/teacher-jobs-near-coimbatore",
    },
    {
      cityID: 563,
      title: "Teacher jobs in Patna",
      link: "/teacher-jobs-in-patna",
    },
    {
      cityID: 4536,
      title: "Teacher jobs in Agra",
      link: "/teacher-jobs-in-agra",
    },
  ],
};

export const designation = {
  slug: "Jobs by Designations",
  data: [
    {
      title: "Mathematics Teacher Jobs",
      link: "/mathematics-teacher-jobs",
      pref_count: 6394,
    },
    {
      title: "English Teacher Jobs",
      link: "/english-teacher-jobs",
      pref_count: 4157,
    },
    {
      title: "Teacher Jobs",
      link: "/teacher-jobs",
      pref_count: 4023,
    },
    {
      title: "Biology Teacher Jobs",
      link: "/biology-teacher-jobs",
      pref_count: 2464,
    },
    {
      title: "Science Teacher Jobs",
      link: "/science-teacher-jobs",
      pref_count: 1650,
    },
    {
      title: "Hindi Teacher Jobs",
      link: "/hindi-teacher-jobs",
      pref_count: 1369,
    },
    {
      title: "Social Science Teacher Jobs",
      link: "/social-science-teacher-jobs",
      pref_count: 1363,
    },
    {
      title: "Physics Teacher Jobs",
      link: "/physics-eacher-jobs",
      pref_count: 1259,
    },
    {
      title: "Chemistry Teacher Jobs",
      link: "/chemistry-teacher-jobs",
      pref_count: 1092,
    },
    {
      title: "Assistant Teacher Jobs",
      link: "/assistant-teacher-jobs",
      pref_count: 918,
    },
    {
      title: "Computer Science Teacher Jobs",
      link: "/computer-science-teacher-jobs",
      pref_count: 752,
    },
    {
      title: "History Teacher Jobs",
      link: "/history-teacher-jobs",
      pref_count: 597,
    },
    {
      title: "Computer Teacher Jobs",
      link: "/computer-teacher-jobs",
      pref_count: 575,
    },
    {
      title: "Commerce Teacher Jobs",
      link: "/commerce-teacher-jobs",
      pref_count: 518,
    },
    {
      title: "Economics Teacher Jobs",
      link: "/economics-teacher-jobs",
      pref_count: 511,
    },
    {
      title: "Accountancy Teacher Jobs",
      link: "/accountancy-teacher-jobs",
      pref_count: 492,
    },
    {
      title: "Academic Coordinator Jobs",
      link: "/academic-coordinator-jobs",
      pref_count: 483,
    },
    {
      title: "English Language Teacher Jobs",
      link: "/english-language-teacher-jobs",
      pref_count: 463,
    },
    {
      title: "General Teacher Jobs",
      link: "/general-teacher-jobs",
      pref_count: 437,
    },
    {
      title: "Geography Teacher Jobs",
      link: "/geography-teacher-jobs",
      pref_count: 421,
    },
    {
      title: "Academic Counsellor Jobs",
      link: "/academic-counsellor-jobs",
      pref_count: 390,
    },
    {
      title: "Accountant Jobs",
      link: "/accountant-jobs",
      pref_count: 390,
    },
    {
      title: "Administration Executive Jobs",
      link: "/administration-executive-jobs",
      pref_count: 364,
    },
    {
      title: "Physical Education Teacher Jobs",
      link: "/physical-education-teacher-jobs",
      pref_count: 362,
    },
    {
      title: "Political Science Teacher Jobs",
      link: "/political-science-teacher-jobs",
      pref_count: 348,
    },
  ],
};
