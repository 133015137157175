import Drawer from "@mui/material/Drawer";
import Image from "next/image";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "next/link";
import { useRouter } from "next/router";
import { categories, location, designation } from "../jobs/data";
import generateUrl from "@/hooks/generateJobDetailsUrl";

function MobileDrawer({ open, onClose }) {
  const router = useRouter();

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => onClose()}
      sx={{
        "& a": { color: (theme) => theme.palette.dark.main, fontSize: 14 },
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: 300 },
      }}
    >
      <Stack
        sx={{ my: 2, mr: 2 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Image
          src="/logo.svg"
          height={25}
          alt="Jobs in Education"
          title="Jobs in Education"
          width={100}
          className="side-menu-logo ps-3"
          loading="lazy"
        />
        <Box onClick={() => onClose()} sx={{ color:"#2a5798", cursor:"pointer" }}>
          <i className="icon-close" style={{ fontSize: "16px" }} />
        </Box>
      </Stack>
      <Stack
        m={2}
        rowGap={1}
        sx={{
          a: { display: "block", p: "4px 0", "&.active": { color: "#2A5798" } },
          "& .MuiAccordion-root": {
            "&.Mui-expanded": {
              m: "0",
            },
          },
          "& .MuiAccordionSummary-root": {
            "&.Mui-expanded": {
              minHeight: "48px",
            },
          },
          "& .MuiAccordionSummary-content": {
            "&.Mui-expanded": {
              m: "12px 0px",
            },
          },
        }}
      >
        <Link
          href="/"
          onClick={() => onClose()}
          className={router.pathname === "/" ? "active" : ""}
        >
          Home
        </Link>
        <Link
          href="/about"
          onClick={() => onClose()}
          className={router.pathname === "/about" ? "active" : ""}
        >
          About Us
        </Link>
        <Typography variant="subtitle2">Jobs</Typography>
        <Accordion elevation={0} sx={{ ":before": { content: "none" } }}>
          <AccordionSummary
            expandIcon={<i className="icon-arrow-down" />}
            sx={{
              bgcolor: (theme) => theme.palette.primary[900],
              minHeight: 20,
            }}
          >
            Jobs by Categories
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxHeight: 200,
              overflowY: "scroll",
            }}
          >
            {Boolean(categories?.data && Array.isArray(categories?.data) && categories?.data?.length > 0) && categories?.data?.map((data, i) => (
              <Link
                key={data?.institutionCategoryName}
                href={"/" + generateUrl(data?.searchKeyword.toLowerCase())}
                onClick={() => onClose()}
              >
                {data?.institutionCategoryName}
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} sx={{ ":before": { content: "none" } }}>
          <AccordionSummary
            expandIcon={<i className="icon-arrow-down" />}
            sx={{
              bgcolor: (theme) => theme.palette.primary[900],
              minHeight: 20,
            }}
          >
            Jobs by Locations
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: 200, overflowY: "scroll" }}>
            {location?.data?.map((data, i) => (
              <Link key={i} href={data.link} onClick={() => onClose()}>
                {data?.title}
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} sx={{ ":before": { content: "none" } }}>
          <AccordionSummary
            expandIcon={<i className="icon-arrow-down" />}
            sx={{
              bgcolor: (theme) => theme.palette.primary[900],
              minHeight: 20,
            }}
          >
            Jobs by Designations
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: 200, overflowY: "scroll" }}>
            {designation?.data?.map((data, i) => (
              <Link key={i} href={data.link} onClick={() => onClose()}>
                {data?.title}
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Link
          href="/plans"
          onClick={() => onClose()}
          className={router.pathname === "/plans" ? "active" : ""}
        >
          Plans
        </Link>
        {/* <Link
          href="/concierge-services"
          shallow
          passHref
          onClick={() => onClose()}>
          Concierge Service
        </Link> */}
      </Stack>
    </Drawer>
  );
}

export default MobileDrawer;
