import React from "react";
import { useRouter } from "next/router";

import Image from "next/image";
import dynamic from "next/dynamic";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { useDispatch } from "react-redux";
import { updateAuthTabStatus } from "@/store/slices/commonSlice";
import { setLoginOpen } from "@/store/slices/LoginSlice";
import Link from "next/link";

const Jobs = dynamic(async () => import("../jobs"), { ssr: false });

function Desktop() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const router = useRouter();
  const [position, setPosition] = React.useState({ top: 0, left: 0 });

  const handleClick = (event) => {
    const element = document.getElementById("nav-jobs-popover");
    const position = element.getBoundingClientRect();
    setPosition({
      top: parseInt(position.top.toFixed()),
      left: parseInt(position.left.toFixed()),
    });
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const open = Boolean(anchorEl);

  const JOBS = React.useMemo(
    () => (
      <Jobs open={open} onClose={() => setAnchorEl(null)} position={position} />
    ),
    [open, position]
  );
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" flexWrap="nowrap">
        <Grid item md={2} textAlign="start">
          <Link href="/">
            <Image
              src="/logo.svg"
              height={54}
              alt="Jobs in Education"
              title="Jobs in Education"
              width={180}
              priority
            />
          </Link>
        </Grid>
        <Grid item md={7} lg={6}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            minWidth={500}
            sx={{
              "& a": {
                color: "#555555",
                ":hover": {
                  color: "#2A5798",
                },
                "&.active": {
                  color: "#2A5798",
                },
              },
            }}
            columnGap={{ xs: 0, md: 2, lg: 3 }}
          >
            <Link
              aria-current="page"
              href="/"
              className={router.pathname === "/" ? "active" : ""}
            >
              Home
            </Link>
            <Box
              id={"nav-jobs-popover"}
              onClick={handleClick}
              color="inherit"
              sx={{
                fontSize: 16,
                color: "#555555",
                fontWeight: "400",
                p: "0",
                cursor: "pointer",
                whiteSpace:"nowrap",
                "&:hover": {
                  background: "#fff",
                  color: "#2A5798",
                },
              }}
            >
              Jobs <i className="icon-arrow-down" style={{ fontSize: "13px", paddingLeft: "4px" }} />
            </Box>
            <Link
              href="/plans"
              className={router.pathname === "/plans" ? "active" : ""}
            >
              Plans
            </Link>
            <Link
              href="https://calendly.com/connectwithjobsineducation/book-a-demo-with-jobs-in-education?month=2023-06"
              target="_blank"
              rel="nofollow"
            >
              <Box
                sx={{
                  borderRadius: "32px",
                  color: "#555555",
                  border: "1px solid #F6B333",
                  padding: "4px 12px",
                  whiteSpace:"nowrap",
                }}
              >
                <i className="icon-date" style={{ marginRight: "4px" }} /> Book a Demo
              </Box>
            </Link>
          </Stack>
        </Grid>
        <Grid item md={3} lg={4}>
          <Stack direction="row" columnGap={1} justifyContent="end">
            <Box
              title="Login"
              sx={{
                textTransform: "unset",
                border: "1px solid #2a5798",
                transition: "0.3s ease-in-out",
                maxHeight: 45,
                px: { xs: "14px", lg: "30px" },
                whiteSpace: "nowrap",
                borderRadius: 2,
                fontWeight: 500,
                padding: "8px",
                cursor: "pointer",
                color: "#2A5798",
                fontSize: { xs: "14px", lg: "16px" },
                "&:hover": {
                  bgcolor: "#2a5798",
                  color: "#fff",
                },
              }}
              onClick={() => {
                dispatch(setLoginOpen(true));
                dispatch(updateAuthTabStatus("Seeker"));
              }}
            >
              Login
            </Box>
            <Box
              title="Registration for Free"
              sx={{
                border: "1px solid #2a5798",
                whiteSpace: "nowrap",
                textTransform: "unset",
                transition: "0.3s ease-in-out",
                bgcolor: "#2a5798",
                color: "#fff",
                borderRadius: 2,
                px: { xs: "14px", lg: "30px" },
                maxHeight: 45,
                fontWeight: 500,
                padding: "8px",
                cursor: "pointer",
                fontSize: { xs: "14px", lg: "16px" },
                "&:hover": {
                  bgcolor: "#fff",
                  color: "#2a5798",
                },
              }}
              onClick={() => {
                dispatch(setLoginOpen(true));
                dispatch(updateAuthTabStatus("Employer"));
              }}
            >
              Registration for Free
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {JOBS}
    </>
  );
}

export default React.memo(Desktop);
