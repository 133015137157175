import React from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import Box from "@mui/material/Box";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";

const AuthModals = dynamic(async () => import("./AuthModals"), { ssr: false });
import Desktop from "./desktop";
import Mobile from "./mobile";

function HideOnScroll(props) {
  const { children, window, device } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window ? window() : undefined,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 0,
    sx: {
      transition: "0.3s all",
      py: trigger
        ? device === "mobile"
          ? 0
          : 1
        : device === "mobile"
        ? 0
        : 1.4,
      width: "100%",
      "& img": {
        transition: "0.3s all",
        height: trigger
          ? device === "mobile"
            ? 32
            : 50
          : device === "mobile"
          ? 40
          : 60,
        width: trigger
          ? device === "mobile"
            ? "auto"
            : 160
          : device === "mobile"
          ? "auto"
          : 180,
      },
      "& a": { transition: "0.3s all", fontSize: trigger ? 15 : 16 },
    },
  });
}

function Header({ device }) {
  const router = useRouter();
  return (
    <>
      <HideOnScroll device={device}>
        <AppBar color="inherit" elevation={0}>
          {/* {device === "desktop" ? (
            ""
          ) : (
            <Toolbar
              disableGutters
              sx={{
                minHeight: "48px",
                bgcolor: (theme) => theme.palette.warning.light,
                borderBottom: "1px solid",
                borderColor: (theme) => theme.palette.warning.main,
                justifyContent: "space-between",
                px: 2,
              }}
            >
              <Typography variant="subtitle2">Jobs In Education App</Typography>
              <Box
                id="app-link"
                onClick={() =>
                  router.push(
                    "https://jobsineducation.page.link/?link=https://www.jobsineducation.net&apn=com.jie.ajpl&isi=6444102426&ibi=com.jie.ajpl&ifl=https://apps.apple.com/in/app/jobs-in-education/id6444102426"
                  )
                }
                className="contained-button"
                sx={{ borderRadius: "20px !important" }}
              >
                Open App
              </Box>
            </Toolbar>
          )} */}
          <Toolbar
            disableGutters
            sx={{ bgcolor: { xs: "#FAFAFA", md: "#fff", minHeight:"65px" } }}
          >
            <Container maxWidth="xl">
              {device === "desktop" ? <Desktop /> : <Mobile />}
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <AuthModals device={device} />
    </>
  );
}
export default Header;
